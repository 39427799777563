import { WorkExperience } from "./workExperience";

export class WorkExperiences {
    constructor(workExperiences) {
        this.workExperiences = workExperiences
    }

    to2dArray() {
        const title = ["Job Title", "Company name", "Period", "Details"]
        const experiences = this.workExperiences.map(item => item.toArray())
        return [title, ...experiences]
    }

    toObjArray() {
        return this.workExperiences.map(item => item.toObject())
    }

    static from2dArray(array) {
        // Create a local copy of an array without the first element,
        // as the first element represents titles.
        const localArray = array.shift()

        return new WorkExperiences(
            array.map(item => WorkExperience.fromArray(item))
        )
    }

    static fromObjList(objList) {
        return new WorkExperiences(
            objList.map(item => WorkExperience.fromObject(item))
        )
    }
}
