import DetailsPageSidebar from "@/components/other/details-page-sidebar/DetailsPageSidebar";
import { userFormatText } from "@/utils/userFormatText";

export default function CandidateSidebar({ candidateInfo, userInfo }) {
  return (
    <DetailsPageSidebar >

      <div className="">
        <div className="sidebar_item_title">Joined</div>
        <div className="sidebar_content_info text-primary">
          {userInfo?.createdAt?.split('T')[0]}
        </div>
      </div>

      <div className="">
        <div className="sidebar_item_title">Location</div>
        <div className="sidebar_content_info">
          {userFormatText(candidateInfo?.location) || "Not Added"}
        </div>
      </div>

      <div className="">
        <div className="sidebar_item_title">Industry</div>
        <div className="sidebar_content_info">
          {userFormatText(candidateInfo?.industry) || "Not Added"}
        </div>
      </div>

      <div className="">
        <div className="sidebar_item_title">Status</div>
        <div className="sidebar_content_info">
          {userFormatText(userInfo?.status) || "Not Added"}
        </div>
      </div>

    </DetailsPageSidebar>
  );
}
