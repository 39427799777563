import HeadingButtons from './part_headingButtons';
import AlignmentButtons from './part_alignmentButtons';
import ListButtons from './part_listButtons';
import UndoRedoButtons from './part_undoRedoButtons';
import TextStyleButtons from './part_textStyleButtons';

const MenuBar = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div id="toolbar">
            <TextStyleButtons editor={editor} />
            <HeadingButtons editor={editor} />
            <ListButtons editor={editor} />
            <UndoRedoButtons editor={editor} />
            <AlignmentButtons editor={editor} />
        </div>
    );
};

export default MenuBar