import React, { useLayoutEffect, useRef, useState } from "react";
import hero_logo_1 from "@/assets/brands/hero/hero-logo-1.png";
import hero_logo_2 from "@/assets/brands/hero/hero-logo-2.png";
import hero_logo_3 from "@/assets/brands/hero/hero-logo-3.png";
import hero_logo_4 from "@/assets/brands/hero/hero-logo-4.png";
import hero1 from "@/assets/images/hero/hero-01.png";
import hero2 from "@/assets/images/hero/hero-02.png";
import hero3 from "@/assets/images/hero/hero-03.png";
import { BiSearchAlt } from "react-icons/bi";
import Badge from "@/components/ui/Badge";
import { gsap } from "gsap";
import { useNavigate } from "react-router-dom";
import "./Banner.css";

const Landing = () => {
  const fractionals = [
    hero1,
    hero2,
    hero3
  ]

  const fractionalRefs = useRef([]);

  const keywords = [
    "Data Scientist",
    "AI expert",
    "CTO",
    "CMO",
    "Sales expert",
    "Team Lead",
    "Full stack",
    "Python developer",
    "CCO",
  ];

  const el = useRef();
  const tl = useRef();
  const tl2 = useRef();

  useLayoutEffect(() => {

    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ repeat: -1 });

      fractionalRefs.current.forEach((ref) => {
        tl.current.to(ref, { opacity: 1, duration: 2 });
        tl.current.to(ref, { opacity: 0, duration: 2, delay: 2 });
      });

      tl2.current = gsap
        .timeline()
        .from("#hero-title", { delay: 0.2, y: 50, opacity: 0, duration: 0.3 })
        .from("#hero-subtitle", { y: 50, opacity: 0, duration: 0.3 })
        .from("#search-container", { y: 50, opacity: 0, duration: 0.3 })
        .from("#search-button", {
          x: -100,
          opacity: 0,
          duration: 0.5,
          ease: "power2",
        })
        .from(".badge-container", { opacity: 0 })
        .from(".badge", { opacity: 0, y: 50, stagger: 0.1 });
    }, el);

    let cards = gsap.utils.toArray(".statCard");
    
    const movement = (e) => {
      cards.forEach((card, index) => {
        const depth = 90;
        const moveX = (e.pageX - window.innerWidth / 2) / depth;
        const moveY = (e.pageY - window.innerHeight / 2) / depth;
        index++;
        gsap.to(card, {
          x: moveX * index,
          y: moveY * index,
        });
      });
    };

    document.addEventListener("mousemove", movement);

    return () => {
      ctx.revert();

      document.removeEventListener("mousemove", movement);
    };
  }, []);

  const [searchParam, setSearchParam] = useState("");
  const navigate = useNavigate();

  const handleOnSearch = () => {
    navigate(`/candidate-listing/?keywords=${searchParam}`);
  };

  return (
    <>
      <div ref={el} className="my-20 h-screen max_container pt-20">

        {/* List all of the fractional experts photos. */}
        <div id="fractionals-container" className="mt-20 hidden md_lg:block max-w-2xl rounded-full absolute left-1/2 -translate-x-1/2 overflow-hidden -z-10">
          {fractionals.map((item, index) => (
            <img
              key={index}
              ref={el => (fractionalRefs.current[index] = el)}
              id="fractional"
              src={item}
              alt=""
              className="hidden md_lg:block w-100 h-100 mt-20"
            />
          ))}
        </div>

        <div className="lg:h-[90%]  flex items-center z-10 relative">
          <div className="flex w-full">
            <div className="w-full md_lg:w-1/2 flex flex-col items-start">

              {/* Landing page title and subtitle. */}
              <div>
                <h1 id="hero-title" className="heroElement font-bold text-6xl">
                  Grow your<br />business with
                </h1>
                <h1 id="hero-title" className="heroElement font-bold text-6xl">
                  <span className="text-red-600 ">Fractional experts</span>
                </h1>

                <p id="hero-subtitle" className="mt-5 text-lg">
                  Fractional experts for a fraction of a cost
                </p>
              </div>
              


              {/* Search bar to search for fractional experts. */}
              <div
                id="search-container"
                className="bg-white rounded-full p-3 flex w-full max-w-xl overflow-hidden mt-5  shadow-lg"
              >
                <input
                  className="flex-auto text-lg p-2 border-none shadow-none outline-none focus:ring-0"
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Find a fractional expert"
                  onChange={(e) => setSearchParam(e.target.value)}
                />
                <button
                  id="search-button"
                  className="p-2 rounded-full bg-primary  h-14 w-14 grid place-items-center"
                  onClick={handleOnSearch}
                >
                  <BiSearchAlt size="23" color="white" />
                </button>
              </div>
              
              {/* Popular search chips. */}
              <div className="mt-16">
                <h2 className="badge-container">Popular Search</h2>
                <div className="mt-3 max-w-xl flex flex-wrap  gap-3">
                  {keywords.map((item) => (
                    <Badge key={item} className="badge">
                      {item}
                    </Badge>
                  ))}
                </div>
              </div>

            </div>
            <div className="lg:flex hidden flex-col justify-between ">
              <div className="statCard rounded-3xl shadow-2xl p-7 bg-white relative left-[100%]">
                <div>
                  <span className="text-2xl font-bold">47 </span>
                  <span>Fractional experts</span>
                </div>
                <p className="font-light text-gray-500">
                  To grow your business
                </p>
              </div>
              <div className="statCard rounded-3xl shadow-2xl p-7 bg-white relative left-[80%]">
                <div>
                  <span className="text-2xl font-bold">8 </span>
                  <span>Active companies</span>
                </div>
                <p className="font-light text-gray-500">
                  Featured on our site
                </p>
              </div>
              <div className="statCard rounded-3xl shadow-2xl p-7 bg-white relative left-[60%]">
                <div>
                  <span className="text-2xl font-bold">25 </span>
                  <span>Job posts</span>
                </div>
                <p className="font-light text-gray-500">
                  Looking for fractional experts
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center gap-8 flex-wrap">
          <div>
            <img className="w-28 contrast-0" src={hero_logo_1} alt="" />
          </div>
          <div>
            <img className="w-28 contrast-0" src={hero_logo_2} alt="" />
          </div>
          <div>
            <img className="w-28 contrast-0" src={hero_logo_3} alt="" />
          </div>
          <div>
            <img className="w-28 contrast-0" src={hero_logo_4} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
