import DashboardHeader from "./../../../../components/dashboard/DashboardHeader";
import { useEffect, useState } from "react";
import { industries, location } from "@/constants/jobInfo";
import FormInput from "./../../../../components/form/FormInput";
import FormTextarea from "./../../../../components/form/FormTextarea";
import FormSelect from "@/components/form/FormSelect";
import FormImg2 from "@/components/form/FormImg2";
import Form from "../../../../components/form/Form";
import AddSkill from "./components/AddSkill";
import { yupResolver } from "@hookform/resolvers/yup";
import { candidateProfileSchema } from "../../../../schema/candidateProfile";
import ButtonPrimary from "../../../../components/ui/ButtonPrimary";
import { catchAsync } from "@/helpers/catchAsync";
import { useEditProfileMutation } from "./../../../../redux/api/candidate";
import toast from "react-hot-toast";
import { useMeQuery } from "../../../../redux/api/user";
import AddResume2 from "./components/AddResume2";
import { ActiveTable } from 'active-table-react';
import { useRef } from "react";
import { WorkExperiences } from "./workExperiences";
import { Educations } from "./educations";
import TipTap from "../../../../wip_components/tipTapEditor/editor";

export default function CandidateEditProfile() {
  const workExperienceTableRef = useRef(null)
  const educationTableRef = useRef(null)
  const aboutSectionRef = useRef(null)

  const [skills, setSkills] = useState([])
  const { data, isLoading: meLoading } = useMeQuery();
  const [editProfile, { isLoading }] = useEditProfileMutation();
  let defaultValues = data?.data;

  const onSubmit = catchAsync(async (data) => {
    // Handle data from both work and education tables.
    data.workExperience = WorkExperiences.from2dArray(workExperienceTableRef.current.getData()).toObjArray()
    data.educationTraining = Educations.from2dArray(educationTableRef.current.getData()).toObjArray()
    data.about = JSON.stringify(aboutSectionRef.current.get())
    console.log(data.about)
    console.log(JSON.stringify(data))
    const res = await editProfile(data).unwrap();
    toast.success(res?.message);
  });

  useEffect(() => {
    if (!defaultValues) return

    // Handle work experience.
    const defaultExperiences = defaultValues?.workExperience || []
    const workExperiences = WorkExperiences.fromObjList(defaultExperiences)
    workExperienceTableRef.current.updateData(workExperiences.to2dArray())

    // Handle education & training.
    const defaultEducations = defaultValues?.educationTraining || []
    const educations = Educations.fromObjList(defaultEducations)
    educationTableRef.current.updateData(educations.to2dArray())

    // Handle "about" text editor.
    if (defaultValues?.about) {
      aboutSectionRef.current.set(JSON.parse(defaultValues?.about))
    }
  },
    // Make sure "useEffect" runs when data loading from API is complete (meLoading). Initially when this component
    // loads, it doesn't have data yet. It results in an empty rendered table. Once loading is complete
    // we want to rerender our table (by calling updateData) with the new available information.
    [meLoading])

  return (
    <div>
      <DashboardHeader
        title="Edit Profile"
        subtitle="Edit your candidate profile page info."
      />

      <div className="">
        <Form
          defaultValues={defaultValues}
          submitHandler={onSubmit}
          resolver={yupResolver(candidateProfileSchema)}
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-8">
              <FormInput
                name="name"
                label="Name"
                placeholder="Your Name"
                type="text"
                mandatory={true}
              />
              <div className="flex gap-5">
                <FormInput
                  name="email"
                  label="Email"
                  placeholder="Your Email"
                  type="email"
                  divClass=" w-1/2 flex-grow"
                  disabled
                />
                <FormInput
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Your Phone"
                  type="text"
                  mandatory={true}
                  divClass=" w-1/2 flex-grow"
                />
              </div>
              <FormInput
                name="title"
                label="Title"
                placeholder="Your Title"
                type="text"
                mandatory={true}
              />

              <label className="text-[13px] font-medium leading-5 ml-2 mb-2" >
                {"About"} {<span className="text-red-700">*</span>}
              </label>
              <TipTap ref={aboutSectionRef}/>
            </div>

            <div className="col-span-4">
              <FormImg2
                label="Upload cover photo"
                id="banner"
                name="banner"
                height="h-40"
                width="w-full"
              />

              <FormImg2
                label="Upload Image"
                id="avatar"
                name="avatar"
                height="h-32"
                width="w-32"
              />
            </div>
          </div>

          <div className="flex gap-5 mb-8">
            <FormSelect
              options={industries}
              name="industry"
              label="Industry"
              mandatory={true}
              placeholder="Select Industry"
              divClass="w-1/2 flex-grow"
            />
            <FormSelect
              options={location}
              name="location"
              label="Location"
              placeholder="Select Location"
              mandatory={true}
              divClass="w-1/2 flex-grow"
            />
          </div>

          <div className="mt-12">
            <h2 className="heading_2">Skills</h2>
            <AddSkill skills={skills} setSkills={setSkills} />
          </div>

          <div className="mt-12">
            <h2 className="heading_2">Work Experience</h2>
            <div className="w-full">
              <ActiveTable
                ref={workExperienceTableRef}
                tableStyle={{
                  "borderRadius": "10px",
                  "boxShadow": "rgb(172 172 172 / 17%) 0px 0.5px 1px 0px",
                  "width": "100%"
                }}
                customColumnsSettings={[
                  { "headerName": "Job Title", "defaultText": "Insert Planet", "cellStyle": { "width": "250px" }, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false },
                  { "headerName": "Company name", "cellStyle": { "width": "250px" }, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false },
                  { "headerName": "Period", "cellStyle": { "width": "200px" }, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false },
                  { "headerName": "Details", "cellStyle": {}, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false }
                ]}
                headerStyles={{ default: { backgroundColor: '#d6d6d630' } }}
              />
            </div>
          </div>

          <div className="mt-12">
            <h2 className="heading_2">Education & Training</h2>
            <div className="w-full">
              <ActiveTable
                ref={educationTableRef}
                tableStyle={{
                  "borderRadius": "10px",
                  "boxShadow": "rgb(172 172 172 / 17%) 0px 0.5px 1px 0px",
                  "width": "100%"
                }}
                customColumnsSettings={[
                  { "headerName": "Name", "defaultText": "Insert Planet", "cellStyle": { "width": "250px" }, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false },
                  { "headerName": "Institution", "cellStyle": { "width": "250px" }, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false },
                  { "headerName": "Period", "cellStyle": { "width": "200px" }, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false },
                  { "headerName": "Details", "cellStyle": {}, "defaultColumnTypeName": "Text", "isHeaderTextEditable": false, "isColumnResizable": false }
                ]}
                headerStyles={{ default: { backgroundColor: '#d6d6d630' } }}
              />
            </div>
          </div>

          <div className="mt-12">
            <h2 className="heading_2">Resume</h2>
            <AddResume2 resumeData={data?.data?.resume} meLoading={meLoading} />
          </div>

          <div className="mt-12">
            <ButtonPrimary
              className="btn_secondary"
              display="Update Profile"
              isLoading={isLoading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
}
