import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../wip_components/menu/Navbar";
import Footer from "../../wip_components/footer/Footer";
import ScrollToTop from "../../helpers/ScrollToTop";

const Main = () => {

  return (
    <div>
      <ScrollToTop />
      <Navbar/>
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Main;
