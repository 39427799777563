export class WorkExperience {
    constructor(title, company, period, details) {
        this.title = title
        this.company = company
        this.period = period
        this.details = details
    }

    toObject() {
        return {
            position: this.title,
            company: this.company,
            timePeriod: this.period,
            details: this.details
        }
    }

    toArray() {
        return [
            this.title,
            this.company,
            this.period,
            this.details
        ]
    }

    static fromObject(obj) {
        return new WorkExperience(
            obj.position, 
            obj.company, 
            obj.timePeriod, 
            obj.details
        )
    }

    static fromArray(array) {
        return new WorkExperience(
            array[0], 
            array[1], 
            array[2], 
            array[3]
        )
    }
}
