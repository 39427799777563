// Backend API URL. Fronend client application will send HTTP requests to the backend
// for authentication, creating new jobs, companies, users, etc.
export const BACKEND_API_URL = process.env.REACT_APP_BASE_URL
if (!BACKEND_API_URL) console.log('BACKEND_API_URL is not set!') 

// Currently we use Cloudinary platform to host images publicly. 
// Cloudinary requires to create an upload preset with unsigned public access.
export const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_UPLOAD_PRESET
if (!CLOUDINARY_UPLOAD_PRESET) console.log('CLOUDINARY_UPLOAD_PRESET is not set!') 

// Using Cloudinary API to directly push images from fronted client application. 
// This flow completely skips backend part. Not safe. Needs refactoring.
export const COUDINARY_API = process.env.REACT_APP_CLOUDINARY_URL
if (!COUDINARY_API) console.log('COUDINARY_API is not set!') 
