import PageHeader from "@/components/ui/PageHeader";
import { IoMailUnread } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
// import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { HiBuildingOffice2 } from "react-icons/hi2";
import SectionHeader from "@/components/ui/SectionHeader";

export default function TermsOfService() {
  return (
    <div className=" mt-20">

      <PageHeader
        title="Terms of service"
        subtitle="Our terms of service"
        className="bg-secondaryLight"
      />

      <div className="p-20">
        <div className="max-w-3xl mx-auto text-justify">
        <p>These Terms of Service ("Terms") govern your access to and use of the Fractional Circle platform (the "Platform"), provided by Fractional Circle, a corporation registered in Lithuania with its principal place of business at [Address]. By accessing or using the Platform, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you may not access or use the Platform.</p>
        <br></br>
        <h2 class="font-bold">1. Use of the Platform</h2>
        <br></br>
        <p class="pl-4"><strong>1.1 Eligibility:</strong> You must be at least 18 years old and capable of forming a binding contract to use the Platform. By accessing or using the Platform, you represent and warrant that you meet these eligibility requirements.</p>
        <br></br>
        <p class="pl-4"><strong>1.2 Account Registration:</strong> In order to access certain features of the Platform, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        <br></br>
        <p class="pl-4"><strong>1.3 Prohibited Conduct:</strong> You agree not to engage in any of the following prohibited activities:</p>
        <ul class="list-disc pl-8">
            <li>Violating any applicable laws or regulations;</li>
            <li>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity;</li>
            <li>Interfering with or disrupting the Platform or servers or networks connected to the Platform;</li>
            <li>Attempting to gain unauthorized access to the Platform or any other user's account;</li>
            <li>Using the Platform for any unlawful or unauthorized purpose;</li>
            <li>Uploading, posting, or transmitting any content that is infringing, libelous, defamatory, obscene, pornographic, abusive, offensive, or otherwise objectionable.</li>
        </ul>
        <br></br>
        <h2 class="font-bold">2. Services</h2>
        <br></br>
        <p class="pl-4"><strong>2.1 Platform Services:</strong> The Platform provides a marketplace for individuals seeking part-time employment opportunities ("Candidates") and employers offering part-time positions ("Employers"). The Platform facilitates connections between Candidates and Employers but does not employ or contract with Candidates directly.</p>
        <br></br>
        <p class="pl-4"><strong>2.2 Job Listings:</strong> Employers may post job listings on the Platform detailing part-time employment opportunities. Candidates may apply to these listings through the Platform.</p>
        <br></br>
        <p class="pl-4"><strong>2.3 Candidate Screening:</strong> Platform may perform background checks or other screening procedures on Candidates, but Platform does not guarantee the accuracy or completeness of such screenings.</p>
        <br></br>
        <h2 class="font-bold">3. Payments</h2>
        <br></br>
        <p class="pl-4"><strong>3.1 Payment Processing:</strong> Platform may facilitate payments between Candidates and Employers for services rendered. Platform may deduct fees or commissions from payments made through the Platform.</p>
        <br></br>
        <p class="pl-4"><strong>3.2 Taxes:</strong> Candidates are responsible for any taxes or other obligations arising from their use of the Platform, including income taxes and self-employment taxes.</p>
        <br></br>
        <h2 class="font-bold">4. Intellectual Property</h2>
        <p class="pl-4"><strong>4.1 Ownership:</strong> All content and materials provided through the Platform, including but not limited to text, graphics, logos, images, and software, are the property of Platform or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
        <br></br>
        <h2 class="font-bold">5. Limitation of Liability</h2>
        <br></br>
        <p class="pl-4"><strong>5.1 Disclaimer:</strong> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM IS PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. PLATFORM DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.</p>
        <br></br>
        <p class="pl-4"><strong>5.2 Limitation of Liability:</strong> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, PLATFORM SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF THE PLATFORM.</p>
        <br></br>
        <h2 class="font-bold">6. Governing Law and Dispute Resolution</h2>
        <br></br>
        <p class="pl-4"><strong>6.1 Governing Law:</strong> These Terms shall be governed by and construed in accordance with the laws of Lithuania, without regard to its conflict of laws principles.</p>
        <br></br>
        <p class="pl-4"><strong>6.2 Dispute Resolution:</strong> Any dispute arising out of or relating to these Terms or the Platform shall be resolved exclusively by the state or federal courts located in Lithuania, and you hereby consent to the personal jurisdiction and venue of such courts.</p>
        <br></br>
        <h2 class="font-bold">7. Modification of Terms</h2>
        <p class="pl-4">Platform reserves the right to modify these Terms at any time, effective upon posting the updated Terms on the Platform. Your continued use of the Platform after any such changes shall constitute your consent to such changes.</p>
        <br></br>
        <h2 class="font-bold">8. Termination</h2>
        <p class="pl-4">Platform reserves the right to suspend or terminate your access to the Platform at any time for any reason, without notice or liability.</p>
        <br></br>
        <h2 class="font-bold">9. Contact Us</h2>
        <p class="pl-4">If you have any questions about these Terms or the Platform, please contact us at <a href="mailto:hello@fractionalcircle.com" class="text-blue-500">hello@fractionalcircle.com</a>.</p>
        </div>
      </div>
    </div>
  );
}
