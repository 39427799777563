import * as icons from '@tabler/icons-react';

export default function ListButtons({ editor }) {
    return (
        <div>
            <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                isActive={editor.isActive('bulletList') ? 'is-active' : ''}
            >{<icons.IconList />}</button>

            {/* <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                isActive={editor.isActive('orderedList') ? 'is-active' : ''}
            >{<icons.IconListNumbers />}</button> */}
        </div>
    )
}