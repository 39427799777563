import * as icons from '@tabler/icons-react';

export default function UndoRedoButtons({ editor }) {
    return (
        <div>
            <button
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().chain().focus().undo().run()}
                >{<icons.IconArrowBackUp />}</button>

            <button
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().chain().focus().redo().run()}
                >{<icons.IconArrowForwardUp />}</button>
        </div>
    )
}