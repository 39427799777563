import axios from "axios";

export const fileUploader = async (file) => {
  // Read more about Cloudinary upload presets:
  // https://cloudinary.com/documentation/upload_presets.
  const upload_preset = process.env.REACT_APP_UPLOAD_PRESET
  if (!upload_preset) console.log('Upload preset not defined!')

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", upload_preset);

  const uploaded = await axios.post(
    process.env.REACT_APP_CLOUDINARY_URL,
    formData
  ).catch((err) => {
    console.log(`Failed to upload image. Error message: ${err}.`)
  });

  const fileName = uploaded.data.original_filename;
  const fileURL = uploaded.data.secure_url;

  return { fileName, fileURL };
};
