import SectionHeader from "@/components/ui/SectionHeader";
import ButtonWithArrow from "@/components/ui/ButtonWithArrow";
import Loader from "../../../components/ui/Loader";
import { useGetAllCandidatesQuery } from "@/redux/api/candidate";
import CandidateCard from "./../../candidate-listing/components/CandidateCard";

export default function FeaturedFractional() {
  const { data, isLoading } = useGetAllCandidatesQuery();
  const candidatesData = data?.data;

  return (
    <section className="bg-primaryLight">
      <SectionHeader
        title="Featured Fractional Experts"
        subtitle="Work with the best fractional experts in the world"
      />

      <div className="max_container">
        <div className="grid gap-5 grid-cols-12">
          {isLoading && (
            <div className="col-span-12 my-10">
              <Loader />
            </div>
          )}
          {candidatesData?.slice(0, 6)?.map((candidate, i) => (
            <div key={i} className="col-span-12 md:col-span-6 lg:col-span-4">
              <CandidateCard candidateInfo={candidate}/>
            </div>
          ))}
        </div>
      </div>
      <ButtonWithArrow
        display="All Fractional Experts"
        link="/candidate-listing"
        mt="mt-12"
      />
    </section>
  );
}
