import { createApi } from '@reduxjs/toolkit/query/react'
import { tagTypesArray } from "../tagTypes";
import { axiosBaseQuery } from '@/config/axios/axiosBaseQuery';
import { BACKEND_API_URL } from "@/config";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: axiosBaseQuery({ baseUrl: BACKEND_API_URL }),
  endpoints: () => ({}),
  tagTypes: tagTypesArray,
});

