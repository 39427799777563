export const footerData = [
  // {
  //   title: "For Candidates",
  //   links: [
  //     { display: "Find Jobs", link: "/job-listing" },
  //     { display: "Candidate Dashboard", link: "/dashboard" },
  //     {
  //       display: "My Applications",
  //       link: "/dashboard/candidate/applications",
  //     },
  //     {
  //       display: "Favourite Jobs",
  //       link: "/dashboard/candidate/favourite-jobs",
  //     },
  //     { display: "My Inbox", link: "/dashboard/inbox" },
  //   ],
  // },
  // {
  //   title: "For Companies",
  //   links: [
  //     { display: "Find Candidates", link: "/job-listing" },
  //     { display: "Company Dashboard", link: "/dashboard" },
  //     { display: "Post a Job", link: "/dashboard/company/new-job-offers" },
  //     { display: "Manage Jobs", link: "/dashboard/company/manage-jobs" },
  //     { display: "My Inbox", link: "/dashboard/inbox" },
  //   ],
  // },
  // {
  //   title: "Important Links",
  //   links: [
  //     { display: "Find Jobs", link: "/job-listing" },
  //     { display: "Candidate Dashboard", link: "/dashboard" },
  //     {
  //       display: "My Applications",
  //       link: "/dashboard/candidate/applications",
  //     },
  //     { display: "Manage Jobs", link: "/dashboard/company/manage-jobs" },
  //     { display: "My Inbox", link: "/dashboard/inbox" },
  //   ],
  // },
  {
    title: "Legal",
    links: [
      { display: "Privacy policy", link: "/privacy-policy" },
      { display: "Terms of service", link: "/terms-of-service" },
    ],
  },
  {
    title: "About Us",
    links: [
      { display: "About Us", link: "/" },
      { display: "Blog", link: "/" },
      { display: "FAQs", link: "/" },
      { display: "Contact Us", link: "/contact" },
      { display: "404 Page", link: "/you-hit-a-random-page" },
    ],
  },
];
