import PageHeader from "@/components/ui/PageHeader";
import { IoMailUnread } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
// import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { HiBuildingOffice2 } from "react-icons/hi2";
import SectionHeader from "@/components/ui/SectionHeader";

export default function PrivacyPolicy() {
  return (
    <div className=" mt-20">

      <PageHeader
        title="Privacy Policy"
        subtitle="Our privacy policy"
        className="bg-secondaryLight"
      />

      <div className="p-20">
        <div className="max-w-3xl mx-auto text-justify">
          <p>This Privacy Policy describes how Fractional Circle ("we", "our", or "us") collects, uses, and discloses your personal information when you use our website and services (collectively, the "Platform").</p>
          <br></br>
          <h2 class="font-bold">1. Information We Collect</h2>

          <ul class="list-disc pl-8">
              <li>          
                <p className="pl-4">Personal Information: When you use the Platform, we may collect personal information such as your name, email address, contact information, and other information you provide to us.</p>
              </li>
              <li>   
                <p className="pl-4">Usage Data: We may also collect information about how you access and use the Platform, including your IP address, browser type, operating system, referring URLs, pages visited, and other usage data.</p>
              </li>
              <li>      
                <p className="pl-4">Cookies: We may use cookies and similar tracking technologies to collect information about your interactions with the Platform, including to personalize your experience, analyze trends, and administer the Platform.</p>
              </li>
          </ul>

          <br></br>
          <h2 class="font-bold">2. Use of Information</h2>
          <p class="pl-4">We may use the information we collect for various purposes, including to:</p>
          <ul class="list-disc pl-8">
              <li>Provide and maintain the Platform;</li>
              <li>Communicate with you about your account and our services;</li>
              <li>Personalize your experience on the Platform;</li>
              <li>Analyze usage trends and improve the Platform;</li>
              <li>Prevent fraud and protect the security of the Platform;</li>
              <li>Comply with legal obligations.</li>
          </ul>
          <br></br>
          <h2 class="font-bold">3. Disclosure of Information</h2>
          <p class="pl-4">We may disclose your personal information to third parties in the following circumstances:</p>
          <ul class="list-disc pl-8">
              <li>With your consent;</li>
              <li>To our service providers who assist us in providing the Platform;</li>
              <li>To comply with legal obligations or respond to lawful requests from government authorities;</li>
              <li>To protect our rights, property, or safety or the rights, property, or safety of others.</li>
          </ul>
          <br></br>
          <h2 class="font-bold">4. Data Retention</h2>
          <p class="pl-4">We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, including to provide you with services, comply with legal obligations, resolve disputes, and enforce our agreements.</p>
          <br></br>
          <h2 class="font-bold">5. Your Rights</h2>
          <p class="pl-4">You may have certain rights with respect to your personal information, including the right to access, correct, or delete your personal information. Please contact us using the contact information provided below to exercise these rights.</p>
          <br></br>
          <h2 class="font-bold">6. Children's Privacy</h2>
          <p class="pl-4">The Platform is not directed to children under the age of 18, and we do not knowingly collect personal information from children under the age of 18. If you are a parent or guardian and believe that your child has provided personal information to us, please contact us using the contact information provided below to request deletion of the information.</p>
          <br></br>
          <h2 class="font-bold">7. Changes to this Privacy Policy</h2>
          <p class="pl-4">We may update this Privacy Policy from time to time, and any changes will be effective upon posting the revised policy on the Platform. We encourage you to review this Privacy Policy periodically for updates.</p>
          <br></br>
          <h2 class="font-bold">8. Contact Us</h2>
          <p class="pl-4">If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:hello@fractionalcircle.com" class="text-blue-500">hello@fractionalcircle.com</a>.</p>
        </div>
      </div>
    </div>
  );
}
