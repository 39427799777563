import { Education } from "./education";

export class Educations {
    constructor(educations) {
        this.educations = educations
    }

    to2dArray() {
        const title = ["Name", "Institution", "Period", "Details"]
        const educations = this.educations.map(item => item.toArray())
        return [title, ...educations]
    }

    toObjArray() {
        return this.educations.map(item => item.toObject())
    }

    static from2dArray(array) {
        // Create a local copy of an array without the first element,
        // as the first element represents titles.
        const localArray = array.shift()

        return new Educations(
            array.map(item => Education.fromArray(item))
        )
    }

    static fromObjList(objList) {
        return new Educations(
            objList.map(item => Education.fromObject(item))
        )
    }
}
