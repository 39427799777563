import * as icons from '@tabler/icons-react';

export default function TextStyleButtons({ editor }) {
    return (
        <div>
            <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                isActive={editor.isActive("bold") ? "is-active" : ""}
            >{<icons.IconBold />}</button>

            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                isActive={editor.isActive("italic") ? "is-active" : ""}
            >{<icons.IconItalic />}</button>

            {/* <button
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={!editor.can().chain().focus().toggleUnderline().run()}
                isActive={editor.isActive("underline") ? "is-active" : ""}
            >{<icons.IconUnderline />}</button> */}

            <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
                isActive={editor.isActive("strike") ? "is-active" : ""}
            >{<icons.IconStrikethrough />}</button>

            {/* <button
                id="EditorButton"
                onClick={() => editor.chain().focus().toggleCode().run()}
                disabled={!editor.can().chain().focus().toggleCode().run()}
                isActive={editor.isActive("code") ? "is-active" : ""}
            >{<icons.IconCode />}</button>

            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                isActive={editor.isActive("paragraph") ? "is-active" : ""}
            >{<icons.IconArticle />}</button> */}
        </div>
    )
}