import * as icons from '@tabler/icons-react';

export default function HeadingButtons ({ editor }) {
    return (
        <div>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                isActive={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >{<icons.IconH1 />}</button>

            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                isActive={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >{<icons.IconH2 />}</button>

            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                isActive={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >{<icons.IconH3 />}</button>

            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                isActive={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            >{<icons.IconH4 />}</button>

            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                isActive={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
            >{<icons.IconH5 />}</button>

            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                isActive={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
            >{<icons.IconH6 />}</button>
        </div>
    )
}