  export class Education {
    constructor(name, institution, period, details) {
        this.name = name
        this.institution = institution
        this.period = period
        this.details = details
    }

    toObject() {
        return {
            courseName: this.name,
            institution: this.institution,
            timePeriod: this.period,
            details: this.details
        }
    }

    toArray() {
        return [
            this.name,
            this.institution,
            this.period,
            this.details
        ]
    }

    static fromObject(obj) {
        return new Education(
            obj.courseName, 
            obj.institution, 
            obj.timePeriod, 
            obj.details
        )
    }

    static fromArray(array) {
        return new Education(
            array[0], 
            array[1], 
            array[2], 
            array[3]
        )
    }
}
