import * as icons from '@tabler/icons-react';

export default function AlignmentButtons({ editor }) {
    return (
        <div>
            <button
                onClick={() => editor.chain().focus().setTextAlign("left").run()}
                isActive={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
            >{<icons.IconAlignLeft />}</button>

            <button
                onClick={() => editor.chain().focus().setTextAlign("center").run()}
                isActive={editor.isActive({ textAlign: "center" }) ? "is-active" : ""}
            >{<icons.IconAlignCenter />}</button>

            <button
                onClick={() => editor.chain().focus().setTextAlign("right").run()}
                isActive={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
            >{<icons.IconAlignRight />}</button>

            <button
                onClick={() => editor.chain().focus().setTextAlign("justify").run()}
                isActive={editor.isActive({ textAlign: "justify" }) ? "is-active" : ""}
            >{<icons.IconAlignJustified />}</button>
        </div>
    )
}