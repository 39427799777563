import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import TextAlign from "@tiptap/extension-text-align";
import { EditorContent, Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "./editor.scss"
import MenuBar from "./part_menu";
import React, { Component } from 'react';


class TipTap extends Component {
  constructor(props) {
    super(props);
    this.readonly = props?.readonly || false
    
    this.editor = new Editor({
      extensions: [
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        TextAlign.configure({ types: ["heading", "paragraph"] }),
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          }
        })
      ],
    })

    // Disable editing if we are in readonly mode.
    if (this.readonly) { this.editor.setOptions({ editable: false }) }
  }

  get = () => this.editor.getJSON()
  set = (data) => this.editor.commands.setContent(data)

  render() {
    return (
      <div>
        {/* If the mode is readonly, there is no point to show editor menu. */}
        {!this.readonly && <MenuBar editor={this.editor} />}

        {/* Create editor. Disable editing if it is in readonly mode. */}
        <EditorContent editor={this.editor} className="w-full" />
      </div>
    )
  }
}

export default TipTap